.modal_content {
    position: relative;
    z-index: 1;
    width: 400px;
    padding: 25px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
}

.loader_container {
    position: relative;
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border {
        width: 50px;
        height: 50px;
    }
}

.article_content_dots {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    max-width: 300px;
}

.ql-snow .ql-tooltip {
    transform: translateX(-100px);
}
