input {
    padding: 10px;
    border: none;
    font-size: 0.9rem !important;
}

.Toastify__toast-container {
    min-width: 350px !important;
    right: 250px !important;
}

@media only screen and (max-width: 1000px) {
    .Toastify__toast-container {
        right: 50px !important;
    }
}

.Toastify__toast {
    background-color: #f6ffed;
    height: 50px;
    display: flex;
    min-height: 50px;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    position: relative;
    border-radius: 8px;
}

.Toastify__toast-body {
    flex-direction: row-reverse;
}

.Toastify__toast-body > div:last-child {
    width: 200px !important;
    max-width: 80% !important;
    margin-right: 5px;
    text-align: right;
}

.Toastify__close-button {
    position: absolute !important;
    top: 50%;
    left: 5px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    color: #52c41a;
}

.Toastify__progress-bar {
    opacity: 0 !important;
}

// .Toastify__toast--error
// .Toastify__toast--success
// .Toastify__toast--warning
