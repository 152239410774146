.bowl_pagination {
    background-color: rgb(255, 255, 255);
    height: 90px;
    display: flex;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;

    select {
        box-shadow: 0 1px 4px rgba($color: #000000, $alpha: 0.1) !important;
        height: 40%;
        width: 80px !important;
    }

    ul {
        height: 80%;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        margin: 0;

        li {
            width: 40px;
            height: 40px;

            a {
                width: 100%;
                color: #8b8b97 !important;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;

                &:hover {
                    background-color: #f1f8ff;
                    box-shadow: 0 1px 4px rgba($color: #000000, $alpha: 0.1);
                }
            }

            &.selected a {
                background-color: #0fa7e4;
                color: #fff !important;
            }
        }
    }
}
