.editpost {
    position: relative;
    height: 100vh;
    overflow: hidden;

    .content {
        overflow: scroll;
        height: calc(100vh - 70px);
    }

    .bowl_Save_edits {
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 10;
        height: 75px;
        box-shadow: 0 1px 5px rgba($color: #000000, $alpha: 0.2);
        padding: 0 50px;
        display: flex;
        justify-content: flex-end;

        .content {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }

    .main_content {
        min-height: 100vh;
        display: flex;
        justify-content: space-evenly;

        .bowl {
            background-color: #fff;
            border-radius: 16px;
            box-shadow: 0 1px 5px rgba($color: #000000, $alpha: 0.1);
            margin-top: 20px;
            padding: 20px;
        }

        .cover {
            width: 108px;
            height: 108px;
            min-height: 100px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .center_mark {
                position: absolute;
                svg {
                    width: 30px;
                    height: 30px;
                }
            }

            button {
                position: absolute;
                top: 8px;
                right: 8px;
                background-color: rgba(255, 255, 255, 0.692);
                backdrop-filter: blur(-5px);
                z-index: 10;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.589);
                }
            }

            img,
            video {
                width: 100%;
                height: 100%;
                min-height: 100px;
                border-radius: 12px;
                object-fit: cover;
                box-shadow: 0 1px 6px rgba($color: #000000, $alpha: 0.2);
            }

            &.main_cover {
                margin: auto;
                width: 260px;
                height: 180px;
            }
        }

        .post_content {
            margin: 0 15px;
            width: 740px;
            min-width: 740px;
            margin-bottom: 100px;
            .post_details {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-content: space-between;
                height: 138px;
            }

            .the_article {
                gap: 20px;
                height: auto;

                .editor {
                    height: 300px;
                }

                .title {
                    margin: 20px 0;
                }
            }

            .media {
                .uploads_files {
                    margin: 20px 0;
                    div {
                        padding: 5px;
                    }

                    label {
                        width: 100%;
                        cursor: pointer;

                        &:hover div {
                            background-color: #e1f1fa !important;
                        }
                    }

                    input {
                        display: none;
                    }

                    .files_reader {
                        display: grid;
                        grid-template-columns: repeat(auto-fill, 108px);
                        margin-top: 20px;
                        gap: 5px;
                        justify-content: space-evenly;
                    }

                    .see_more {
                        border-top: 1px solid #e4e6ee;
                        height: 50px;
                        text-align: center;
                        padding: 20px;
                        margin-top: 20px;

                        button {
                            width: 200px;
                            height: 40px;
                            background-color: transparent;
                            outline: none;
                            border: none;
                            color: #0fa7e4;
                            font-size: 1.5rem;
                            font-weight: bold;

                            &:hover {
                                color: #0798d1;
                            }
                        }
                    }
                }
            }
        }

        .post_option {
            width: 360px;
            margin: 0 15px;
            margin-bottom: 40px;

            .pin_article {
                .hide {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #a2a5b5;
                    &.ran {
                        margin-top: 20px;
                    }
                    div {
                        transform: scale(1.1);
                    }
                }

                .note {
                    color: #aaadbb;
                    margin-top: 15px;
                }
            }
        }

        @media only screen and (max-width: 1000px) {
            .post_content {
                width: 90%;
                min-width: 90%;
                margin: 0 auto;

                .post_details {
                    height: 190px;
                    .row {
                        display: flex;
                        flex-direction: row !important;
                    }
                }
            }
            .post_option {
                width: 90%;
                min-width: 90%;
                margin: 0 auto;
                margin-bottom: 40px;
            }

            flex-direction: column;
        }
    }
}

// global

.btn-outlined {
    border: 2px solid #0fa7e4 !important;
    color: #0fa7e4 !important;
    border-radius: 12px !important;

    &:hover {
        border: 2px solid #29c2ff !important;
        color: #29c2ff !important;
    }
}

.ql-editor {
    direction: rtl !important;
    text-align: right !important;
}
