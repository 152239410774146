@import 'theme/palette.scss';
@import 'theme/fonts.scss';
@import 'theme/classes.scss';
@import 'styles/table.scss';
@import 'styles/pagination.scss';
@import 'styles/login.scss';
@import 'styles/header.scss';
@import 'styles/editpost.scss';
@import 'styles/modal.scss';

html,
body,
#root {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-x: hidden !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    margin: 0 !important;
    font-family: $fontFamily;
}

html,
body {
    color: $stylish-black;
}

[dir] {
    font-family: $fontFamily;

    body {
        font-family: $fontFamily;
    }
}

[dir='rtl'] {
    font-family: $rtlFontFamily;

    body {
        font-family: $rtlFontFamily;
    }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #dfe1e5;
    background: #d7d7d7 !important;
    border-radius: 5px !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c7d5ff;
}
div::-webkit-scrollbar:horizontal {
    display: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.form-check-input {
    &:checked {
        background-color: #21b7e3;

        ~ .form-check-label {
            color: #21b7e3;
        }
    }
}

input:-webkit-autofill {
    -webkit-background-clip: text;
}

.form-control {
    font-size: 18px !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
    outline: none !important;
    border-color: none !important;

    &:focus {
        border-color: #ced4da !important;
    }
}
.form-control.is-invalid,
textarea.form-control.is-invalid {
    padding-left: calc(1.5em + 0.75rem);
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-right: 10px !important;
}

.form-control:disabled,
.form-control[readonly] {
    background: #f7f7f7 !important;
    opacity: 1;
}

::-ms-reveal {
    display: none;
}
