.login {
    min-height: 100vh;
    display: flex;
    background-color: #fff;

    .logos {
        width: 40%;
        background-color: #0fa7e4;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;

        svg {
            position: absolute;

            &:first-child {
                top: -70px;
                right: -70px;
            }
            &:last-child {
                bottom: -70px;
                left: -70px;
                transform: rotate(180deg);
            }
        }

        img {
            width: 300px;
        }
    }

    .form_container {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;

        .form {
            width: 330px;

            .input_container {
                margin-top: 20px;
            }
        }

        button {
            background-color: #0fa7e4;
            margin-top: 20px;
        }
    }

    @media only screen and (max-width: 600px) {
        align-items: center;
        justify-content: center;
        .logos {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .form_container {
            background-color: rgba(255, 255, 255, 0.39);
            backdrop-filter: blur(2px);
            border-radius: 10px;
            padding: 10px;
            width: 340px;
            position: absolute;
        }
    }
}
