aside {
    width: 260px;
    padding: 10px 20px;
    background-color: #1e1e2d;
    color: #b6b9c7;
    display: flex;
    flex-direction: column;

    .logo {
        padding: 10px;
        border-bottom: 1px solid #2d2d43;

        img {
            width: 60%;
        }
    }

    .admin_name {
        border-bottom: 1px solid #2d2d43;
        padding: 10px 0;
        font-size: 0.9rem;
        line-height: 30px;
        span {
            display: block;
        }
    }

    ul {
        margin-top: 20px;
        list-style: none;
        padding: 0;

        li {
            margin-top: 7px;
            width: 100%;

            &.line {
                user-select: none;
                cursor: pointer;
            }

            &.line,
            a {
                background-color: #2c2c41;
                color: #b6b9c7;
                display: flex;
                align-items: center;
                height: 40px;
                text-decoration: none;
                padding: 10px;
                align-items: center;
                position: relative;
                font-size: 0.9rem;
                border-radius: 6px;

                .arrow {
                    width: 30px;
                    height: 15px;
                    margin-right: auto;
                }

                &:hover {
                    background-color: #3d3d57;
                }

                &.active {
                    background-color: #0fa7e4;
                    color: #ffffff;
                    svg path {
                        fill: #fff;
                    }
                }

                &.haveNotification::before {
                    background-color: #e52833;
                    position: absolute;
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    left: 10%;
                }

                span {
                    margin-right: 10px;
                }
            }

            @media screen and (max-width: 1380px) {
                &:last-child a {
                    font-size: 0.8rem;
                }
            }
        }
    }

    .logout {
        margin-top: auto;
        display: flex;
        height: 40px;
        border-radius: 6px;
        align-items: center;
        padding: 10px;
        bottom: 20px;
        outline: none;
        color: #fff;
        border: none;
        background-color: transparent;

        &:hover {
            background-color: #3d3d57;
        }
        svg {
            margin-left: 10px;
        }
    }

    .menu_toggle {
        display: none;
        position: absolute;
        left: -40px;
        border-radius: 5px;
        top: 20px;
        box-shadow: 0 1px 7px rgba($color: #000, $alpha: 0.2);

        &:hover {
            box-shadow: 0 1px 17px rgba($color: #000, $alpha: 0.2);
        }
        &.mode {
            transform: rotate(180deg);
        }
    }

    @media only screen and (max-width: 1000px) {
        position: fixed;
        height: 100vh;
        right: -260px;
        z-index: 1000;

        &.mode {
            animation: test 0.5s ease-in-out forwards;
        }

        @keyframes test {
            to {
                right: 0;
            }
        }

        .menu_toggle {
            display: block;
        }
    }
}

@media screen and (max-height: 650px) {
    aside {
        .logo {
            padding: 0px;
        }

        ul {
            li {
                a {
                    height: 30px;
                }
            }
        }
    }
}
