.table_container {
    padding: 20px;
    overflow-x: scroll !important;

    &::-webkit-scrollbar {
        height: 7px !important;
    }

    &::-webkit-scrollbar:horizontal {
        display: inline;
    }

    &::-webkit-scrollbar-thumb {
        background: #0fa7e4 !important;
    }

    table {
        min-width: 700px;
        border-radius: 12px;
        box-shadow: 0 0.1px 3px rgba($color: #000, $alpha: 0.1);
        background-color: #ffffff;

        thead {
            tr {
                font-weight: bold;

                .notesback {
                    background-color: rebeccapurple;
                }

                .last_box {
                    width: 80px;
                }

                .last_box_users {
                    width: 80px;
                    min-width: 80px;
                }

                .type_register {
                    width: 30%;
                }

                .center_Date {
                    width: 40%;
                }

                th {
                    color: #b5b5c3 !important;
                    border-bottom: 1px dashed #e4e6ef !important;

                    // when have arrows
                    .bowl {
                        user-select: none;
                        display: flex;
                        cursor: pointer;

                        &.desc {
                            svg:first-child {
                                stroke: #0fa7e4 !important;
                            }
                            color: #0fa7e4;
                        }

                        &.asc {
                            svg:last-child {
                                stroke: #0fa7e4 !important;
                            }
                            color: #0fa7e4;
                        }

                        .arrows {
                            height: 17px;
                            display: flex;
                            flex-direction: column;
                            margin-right: 5px;

                            svg {
                                fill: #b5b5c3;
                                width: 10px;
                                &:first-child {
                                    transform: rotate(-180deg);
                                }
                            }
                        }
                    }

                    &.bans {
                        &:nth-child(2),
                        &:nth-child(3) {
                            text-align: center;
                            .bowl {
                                justify-content: center;
                            }
                        }
                    }

                    &.id {
                        width: 45px;
                    }

                    &.article {
                        min-width: 250px;
                    }

                    &.details {
                        width: 90px;
                        text-align: center;
                    }

                    &.comments {
                        width: 80px;
                        text-align: center;
                    }
                    &.pin {
                        width: 90px;
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px dashed #e4e6ef !important;
                background-color: #fff !important;
                color: #3f4254 !important;

                &:hover {
                    background-color: #edf2fac9 !important;
                }

                td {
                    position: relative;

                    .avatar_profile {
                        display: flex;
                        align-items: center;
                        line-height: 28px;
                        text-align: right;

                        img {
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                            border-radius: 50%;
                            margin-left: 10px;
                        }

                        span {
                            display: block;
                            &:first-child {
                                font-weight: bold;
                                color: #3f4254;
                            }
                            &:last-child {
                                font-size: 0.9rem;
                                color: #b5b5c3;
                            }
                        }
                    }

                    .date {
                        width: 100px;
                    }

                    .details {
                        text-align: center;
                        span {
                            margin: 5px;
                        }

                        .pop_details {
                            display: none;
                            background-color: #fff;
                            z-index: 10;
                            padding: 5px;
                            width: 160px;
                            position: absolute;
                            top: 0;
                            left: -140px;
                            border-radius: 7px;
                            box-shadow: 0 1px 5px rgba($color: #000000, $alpha: 0.2);

                            .counts {
                                line-height: 35px;
                                font-size: 0.9rem;
                                border-bottom: 1px solid #eee;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }

                            .line {
                                height: 35px;
                                margin-top: 4px;
                                display: flex;
                                align-items: center;
                                padding-right: 10px;

                                svg {
                                    margin-left: 10px;
                                }

                                &:hover {
                                    background-color: #f1f8ff;
                                }
                            }
                        }
                        &:hover .pop_details {
                            display: inline;
                        }
                    }

                    .comments {
                        text-align: center;
                    }

                    .pinarticle {
                        width: 50px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 700px) {
        font-size: 0.9rem;
    }
}

.empty_list {
    background-color: rgb(255, 255, 255);
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 12px;
    flex-direction: column;
    gap: 15px;
}

.message_link {
    background-color: #f1f8ff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    &:hover {
        background-color: #e6f1ff;
    }

    &.comment {
        width: 30px;
        height: 30px;
    }
}
